<template>
<Navbar user="0" />
  <section class="about-area ptb-100">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="about-content left-content">
            <span class="sub-title">About Us</span>
            <h2>Learn New Skills to go ahead for Your Career</h2>
            <h6>
              We can support student forum 24/7 for national and international
              students.
            </h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia laborum.
            </p>
            <div class="features-text">
              <h5>
                <i class="bx bx-planet"></i>A place where you can achieve
              </h5>
              <p>
                Education encompasses both the teaching and learning of
                knowledge, proper conduct, and technical competency.
              </p>
            </div>
            <div class="signature">
              <img src="@/assets/img/signature.png" alt="image" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="about-right-image">
            <img src="@/assets/img/about/4.jpg" alt="image" />
            <img src="@/assets/img/about/3.jpg" alt="image" />
            <div class="text-box">
              <div class="inner">
                Trusted By
                <span>75K+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="about-inner-area">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="about-text">
              <h3>100,000 online courses</h3>
              <p>
                Real innovations and a positive customer experience are the
                heart of successful communication.
              </p>
              <ul class="features-list">
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Activate Listening</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Brilliant minds</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Better. Best. Wow!</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Branding it better!</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="about-text">
              <h3>Expert instruction</h3>
              <p>
                Real innovations and a positive customer experience are the
                heart of successful communication.
              </p>
              <ul class="features-list">
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Creating. Results.</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Expect more</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Good thinking</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> In real we trust</li>
              </ul>
            </div>
          </div>
          <div
            class="
              col-lg-4 col-md-6 col-sm-6
              offset-lg-0 offset-md-3 offset-sm-3
            "
          >
            <div class="about-text">
              <h3>Lifetime access</h3>
              <p>
                Real innovations and a positive customer experience are the
                heart of successful communication.
              </p>
              <ul class="features-list">
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Stay real. Always.</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> We have you covered</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> We turn heads</li>
                <li><svg height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg> Your brand, promoted</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="particles-js-circle-bubble-4"></div>
  </section>

  <WhyUs />

  <section class="story-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="section-title text-left">
            <h2>Our Story</h2>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="story-content">
            <h3>A better future starts here</h3>
            <p>
              Website.com began in 2005. After years in the web hosting
              industry, we realized that it was near impossible for the
              average Jane or Joe to create their own website. Traditional web
              hosting services were simply too complicated, time consuming,
              and expensive to manage.
            </p>
            <h3>A Classical Education for the Future</h3>
            <p>
              We created the Website.com Site Builder with the user's
              perspective in mind. We wanted to offer a platform that would
              require no coding skills or design experience. We keep it
              simple, so users can focus on creating an amazing website that
              reflects their brand. Best of all - it's free. You can get
              online, showcase your brand, or start selling products right
              away.
            </p>
            <h3>A Journey to Excellence</h3>
            <p>
              After seeing an increased need for eCommerce solutions, we
              developed one of the only fully-featured, free and
              commission-free online store builders, allowing business owners
              to launch their online business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="funfacts-style-two ptb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-6">
          <div class="single-funfact">
            <div class="icon">
              <i class="bx bx-group"></i>
            </div>
            <h3 class="odometer" data-count="13">00</h3>
            <p>Million Learners</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="single-funfact">
            <div class="icon">
              <i class="bx bxs-graduation"></i>
            </div>
            <h3 class="odometer" data-count="2">00</h3>
            <p>Million Graduates</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="single-funfact">
            <div class="icon">
              <i class="bx bx-book-reader"></i>
            </div>
            <h3 class="odometer" data-count="2412">00</h3>
            <p>Courses</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="single-funfact">
            <div class="icon">
              <i class="bx bx-world"></i>
            </div>
            <h3 class="odometer" data-count="145">00</h3>
            <p>Countries</p>
          </div>
        </div>
      </div>
    </div>
    <div id="particles-js-circle-bubble"></div>
  </section>

  <section class="values-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="section-title text-left">
            <h2>Our Values</h2>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="values-content">
            <h3>A success-oriented learning environment</h3>
            <p>
              Website.com began in 2005. After years in the web hosting
              industry, we realized that it was near impossible for the
              average Jane or Joe to create their own website. Traditional web
              hosting services were simply too complicated, time consuming,
              and expensive to manage.
            </p>
            <h3>Academic Excellence and Cultural Diversity</h3>
            <p>
              We created the Website.com Site Builder with the user's
              perspective in mind. We wanted to offer a platform that would
              require no coding skills or design experience. We keep it
              simple, so users can focus on creating an amazing website that
              reflects their brand. Best of all - it's free. You can get
              online, showcase your brand, or start selling products right
              away.
            </p>
            <h3>Advancing human understanding</h3>
            <p>
              After seeing an increased need for eCommerce solutions, we
              developed one of the only fully-featured, free and
              commission-free online store builders, allowing business owners
              to launch their online business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Instructor />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Instructor from '@/components/Instructor.vue'
import WhyUs from '@/components/WhyUs.vue'
export default {
  components: { Instructor, WhyUs, Navbar },

}
</script>

<style>

</style>