<template>
    <section class="mission-area ptb-100 jarallax" >
      <div class="container">
        <div class="mission-content">
          <div class="section-title text-left">
            <span class="sub-title">Discover Mission</span>
            <h2>Why our platform in better</h2>
          </div>
          <div class="mission-slides owl-carousel owl-theme">
            <div class="carousel-inner">
              <div >
                <h3>A place where you can achieve</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  nisi ut aliquip ex ea commodo consequat.
                </p>
              <router-link :to="{ name : 'About' }" class="default-btn">
                  <span class="label">Learn More</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>