<template>
    <section class="become-instructor-partner-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="become-instructor-partner-content bg-color">
              <h2>Become an Instructor</h2>
              <p>
                Choose from hundreds of free courses, or get a degree or
                certificate at a breakthrough price. Learn at your own pace.
              </p>
              <router-link :to="{ name: 'TrainerRegister' }" class="default-btn">
                <span class="label">Apply Now</span>
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div
              class="become-instructor-partner-image bg-image1 jarallax"
              data-jarallax='{"speed": 0.3}'
            >
              <img src="@/assets/img/become-instructor.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>